/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information about a single feature according to the current feature configuration.
 */
export type FeatureResponse = {
    /**
     * The identifier of the feature.
     */
    feature: FeatureResponse.feature;
    /**
     * A textual description of the feature.
     */
    description: string;
    /**
     * The current status of the feature.
     */
    status: FeatureResponse.status;
};

export namespace FeatureResponse {

    /**
     * The identifier of the feature.
     */
    export enum feature {
        IDENTITY_VERIFICATION_OPTIONAL = 'IDENTITY_VERIFICATION_OPTIONAL',
        TEST_STRIPS = 'TEST_STRIPS',
        TEST_STRIPS_SHIP_VIA_TRUEPILL = 'TEST_STRIPS_SHIP_VIA_TRUEPILL',
        PROVIDE_FAKE_TRUEPILL_DIAGNOSTICS_RESULTS = 'PROVIDE_FAKE_TRUEPILL_DIAGNOSTICS_RESULTS',
        ELIGIBILITY_UNLISTED_MED = 'ELIGIBILITY_UNLISTED_MED',
        ZENDESK_USER_REQUIRES_ASSISTANCE_EVENTS = 'ZENDESK_USER_REQUIRES_ASSISTANCE_EVENTS',
        SHIPMENT_PAYMENT_BLOCKING = 'SHIPMENT_PAYMENT_BLOCKING',
        SUBSCRIPTION_ACTIVATED_IMMEDIATELY = 'SUBSCRIPTION_ACTIVATED_IMMEDIATELY',
        SUBSCRIPTION_ONBOARDING_FEE = 'SUBSCRIPTION_ONBOARDING_FEE',
        MONTHLY_PAYMENTS_FOR_QUARTERLY_SUBSCRIPTION = 'MONTHLY_PAYMENTS_FOR_QUARTERLY_SUBSCRIPTION',
        GENERIC_PRESCRIPTION = 'GENERIC_PRESCRIPTION',
        ELIGIBILITY_GROUP = 'ELIGIBILITY_GROUP',
        HUB_SPOT_ANALYTICS_DESTINATION = 'HUB_SPOT_ANALYTICS_DESTINATION',
        AT_HOME_PHLEBOTOMY_RULE_BASED_LAB_CADENCE = 'AT_HOME_PHLEBOTOMY_RULE_BASED_LAB_CADENCE',
        DRY_BLOOD_SPOT_RULE_BASED_LAB_CADENCE = 'DRY_BLOOD_SPOT_RULE_BASED_LAB_CADENCE',
        LAB_ORDER_TRIGGERING_DRY_RUN = 'LAB_ORDER_TRIGGERING_DRY_RUN',
        LAB_CADENCE_RULES_A1C = 'LAB_CADENCE_RULES_A1C',
        LAB_CADENCE_RULES_CMP = 'LAB_CADENCE_RULES_CMP',
        LAB_CADENCE_RULES_INITIAL_CMP_FOR_WEIGHT_LOSS_USERS = 'LAB_CADENCE_RULES_INITIAL_CMP_FOR_WEIGHT_LOSS_USERS',
        LAB_CADENCE_RULES_LIPIDS = 'LAB_CADENCE_RULES_LIPIDS',
        LAB_CADENCE_RULES_URINARY_MICROALBUMIN_TO_CREATININE_RATIO = 'LAB_CADENCE_RULES_URINARY_MICROALBUMIN_TO_CREATININE_RATIO',
        LAB_CADENCE_RULES_TSH = 'LAB_CADENCE_RULES_TSH',
        LAB_CADENCE_CREATININE_AS_CMP = 'LAB_CADENCE_CREATININE_AS_CMP',
        TRUEPILL_FILL_REQUEST_AUTOREFILL_CONSENT = 'TRUEPILL_FILL_REQUEST_AUTOREFILL_CONSENT',
        DOSE_SPOT_INTEGRATION = 'DOSE_SPOT_INTEGRATION',
        DOSE_SPOT_API_V2_LOG_REQUESTS = 'DOSE_SPOT_API_V2_LOG_REQUESTS',
        DOSE_SPOT_MEDICATION_SEARCH_ONLY_RETURN_FIRST_PAGE = 'DOSE_SPOT_MEDICATION_SEARCH_ONLY_RETURN_FIRST_PAGE',
        GETLABS_AT_HOME_PHLEBOTOMY = 'GETLABS_AT_HOME_PHLEBOTOMY',
        TRUEPILL_IGNORE_REASSIGN_PRESCRIPTION_TOKENS = 'TRUEPILL_IGNORE_REASSIGN_PRESCRIPTION_TOKENS',
        ELIGIBILITY_MEDICARE = 'ELIGIBILITY_MEDICARE',
        PRESCRIBING_MEDICAL_SUPPLIES = 'PRESCRIBING_MEDICAL_SUPPLIES',
        WEIGHT_LOSS_GLP1_TRIAL = 'WEIGHT_LOSS_GLP1_TRIAL',
        PUSH_NOTIFICATIONS_ANDROID_VIA_FIREBASE = 'PUSH_NOTIFICATIONS_ANDROID_VIA_FIREBASE',
        PUSH_NOTIFICATIONS_VIA_CUSTOMERIO = 'PUSH_NOTIFICATIONS_VIA_CUSTOMERIO',
        LAB_ITEM_AUTOMATIC_UPDATE = 'LAB_ITEM_AUTOMATIC_UPDATE',
        LAB_ITEM_AUTOMATIC_UPDATE_TRIGGER = 'LAB_ITEM_AUTOMATIC_UPDATE_TRIGGER',
        TRANSLATIONS = 'TRANSLATIONS',
        PRESCRIPTION_DIAGNOSIS_CODES = 'PRESCRIPTION_DIAGNOSIS_CODES',
        GLP1_PRESCRIPTION_OPTIONS = 'GLP1_PRESCRIPTION_OPTIONS',
        SGLT2_PRESCRIPTION_OPTIONS = 'SGLT2_PRESCRIPTION_OPTIONS',
        INSULIN_PRESCRIPTION_OPTIONS = 'INSULIN_PRESCRIPTION_OPTIONS',
        HIDE_INSULIN_SIGS = 'HIDE_INSULIN_SIGS',
        PRESCRYPTIVE_PRESCRIPTION_OPTIONS = 'PRESCRYPTIVE_PRESCRIPTION_OPTIONS',
        PRESCRYPTIVE = 'PRESCRYPTIVE',
        EVERNORTH = 'EVERNORTH',
        CONSULTATION_COMPLETED_LIFELINE_ITEM = 'CONSULTATION_COMPLETED_LIFELINE_ITEM',
        CONSULTATION_PERFORMANT_PROVIDER_QUERY = 'CONSULTATION_PERFORMANT_PROVIDER_QUERY',
        CHANGE_HEALTHCARE_INTEGRATION = 'CHANGE_HEALTHCARE_INTEGRATION',
        CHANGE_HEALTHCARE_FORCE_SESSION_ID_RESET = 'CHANGE_HEALTHCARE_FORCE_SESSION_ID_RESET',
        CHANGE_HEALTHCARE_LABCORP_INTEGRATION = 'CHANGE_HEALTHCARE_LABCORP_INTEGRATION',
        CHANGE_HEALTHCARE_QUEST_INTEGRATION = 'CHANGE_HEALTHCARE_QUEST_INTEGRATION',
        CHANGE_HEALTHCARE_QUEST_LAB_RESULTS = 'CHANGE_HEALTHCARE_QUEST_LAB_RESULTS',
        CHANGE_HEALTHCARE_LAB_RESULTS = 'CHANGE_HEALTHCARE_LAB_RESULTS',
        PMP_PCP = 'PMP_PCP',
        ANTICONVULSANT_PRESCRIPTION_OPTIONS = 'ANTICONVULSANT_PRESCRIPTION_OPTIONS',
        ANTIDEPRESSANT_PRESCRIPTION_OPTIONS = 'ANTIDEPRESSANT_PRESCRIPTION_OPTIONS',
        OPIOID_ANTAGONISTS_PRESCRIPTION_OPTIONS = 'OPIOID_ANTAGONISTS_PRESCRIPTION_OPTIONS',
        FILES_SCAN_FOR_VIRUSES = 'FILES_SCAN_FOR_VIRUSES',
        PMP_FAX = 'PMP_FAX',
        PASSWORD_EXPIRATION = 'PASSWORD_EXPIRATION',
        SMARTMETERRPM_INTEGRATION = 'SMARTMETERRPM_INTEGRATION',
        SMARTMETERRPM_ENFORCE_SIGNATURE_VERIFICATION = 'SMARTMETERRPM_ENFORCE_SIGNATURE_VERIFICATION',
        SMARTMETERRPM_TRIGGER_DEVICE_SHIPMENTS = 'SMARTMETERRPM_TRIGGER_DEVICE_SHIPMENTS',
        SMARTMETERRPM_ADD_AUTOMATICALLY_WEIGHT_SCALE_TO_SUBSCRIPTION = 'SMARTMETERRPM_ADD_AUTOMATICALLY_WEIGHT_SCALE_TO_SUBSCRIPTION',
        SMARTMETERRPM_ADD_AUTOMATICALLY_WEIGHT_SCALE_TO_SUBSCRIPTION_FOR_TRANSCARENT_MEMBER = 'SMARTMETERRPM_ADD_AUTOMATICALLY_WEIGHT_SCALE_TO_SUBSCRIPTION_FOR_TRANSCARENT_MEMBER',
        CASHPAY_GENERIC_FUNNEL = 'CASHPAY_GENERIC_FUNNEL',
        DEMO_DATA = 'DEMO_DATA',
        DISABLE_PRESCRIBER_NOTIFICATION_COUNTS_WEBHOOK = 'DISABLE_PRESCRIBER_NOTIFICATION_COUNTS_WEBHOOK',
        END_USER_MFA_TOTP = 'END_USER_MFA_TOTP',
        AUTOMATIC_DETECTION_OF_CONSULT_REASON = 'AUTOMATIC_DETECTION_OF_CONSULT_REASON',
        ONBOARDING_TASK_UPLOAD_LAB_REPORT = 'ONBOARDING_TASK_UPLOAD_LAB_REPORT',
        ONBOARDING_TASK_SELECT_PCP = 'ONBOARDING_TASK_SELECT_PCP',
        ONBOARDING_TASK_SELECT_PHARMACY_INSURANCE = 'ONBOARDING_TASK_SELECT_PHARMACY_INSURANCE',
        ONBOARDING_TASK_ANSWER_POSTSIGNUP_QUESTIONNAIRE = 'ONBOARDING_TASK_ANSWER_POSTSIGNUP_QUESTIONNAIRE',
        ONBOARDING_TASK_SELECT_PREFERRED_PHARMACY = 'ONBOARDING_TASK_SELECT_PREFERRED_PHARMACY',
        ONBOARDING_TASK_ANSWER_INITIAL_LABS_QUESTIONNAIRE = 'ONBOARDING_TASK_ANSWER_INITIAL_LABS_QUESTIONNAIRE',
        ONBOARDING_TASK_SCHEDULE_INITIAL_SYNC_VISIT = 'ONBOARDING_TASK_SCHEDULE_INITIAL_SYNC_VISIT',
        ONBOARDING_TASK_COMPLETE_INITIAL_LAB_ORDER = 'ONBOARDING_TASK_COMPLETE_INITIAL_LAB_ORDER',
        ONBOARDING_TASK_SELECT_MEDICAL_INSURANCE = 'ONBOARDING_TASK_SELECT_MEDICAL_INSURANCE',
        ONBOARDING_TASK_COMPLETE_ONBOARDING = 'ONBOARDING_TASK_COMPLETE_ONBOARDING',
        CHECKIN_TASK_ANSWER_RECURRING_CHECKIN_QUESTIONNAIRE = 'CHECKIN_TASK_ANSWER_RECURRING_CHECKIN_QUESTIONNAIRE',
        RECURRING_TASK_SCHEDULE_FOLLOWUP_SYNC_VISIT = 'RECURRING_TASK_SCHEDULE_FOLLOWUP_SYNC_VISIT',
        ENFORCE_LAB_ORDER_INSURANCE_BILL = 'ENFORCE_LAB_ORDER_INSURANCE_BILL',
        SYNC_CONSULTATION = 'SYNC_CONSULTATION',
        CARE_TASK_SCHEDULE_LABS_APPOINTMENT = 'CARE_TASK_SCHEDULE_LABS_APPOINTMENT',
        INITIAL_ORDER_ONLY_CONSULTATION = 'INITIAL_ORDER_ONLY_CONSULTATION',
        AI_EXPERIMENT_RECOMMEND_THERAPY_CHANGE = 'AI_EXPERIMENT_RECOMMEND_THERAPY_CHANGE',
        AI_EXPERIMENT_PRIOR_SYNC_VISIT_NOTES = 'AI_EXPERIMENT_PRIOR_SYNC_VISIT_NOTES',
        AI_EXPERIMENT_PRIOR_SYNC_VISIT_NOTES_V2 = 'AI_EXPERIMENT_PRIOR_SYNC_VISIT_NOTES_V2',
        AI_EXPERIMENT_PRIOR_AUTH_LETTER = 'AI_EXPERIMENT_PRIOR_AUTH_LETTER',
        AI_EXPERIMENT_CAREASSISTANT_HTN = 'AI_EXPERIMENT_CAREASSISTANT_HTN',
        AI_EXPERIMENT_CAREASSISTANT_STATIN = 'AI_EXPERIMENT_CAREASSISTANT_STATIN',
        WELCOME_MESSAGE_VIA_BACKEND = 'WELCOME_MESSAGE_VIA_BACKEND',
        USER_ELIGIBILITY_CHECK = 'USER_ELIGIBILITY_CHECK',
        CONNECTIVE_HEALTH_INTEGRATION = 'CONNECTIVE_HEALTH_INTEGRATION',
        CONNECTIVE_HEALTH_SIMULATE_RESULTS = 'CONNECTIVE_HEALTH_SIMULATE_RESULTS',
        APERO_HEALTH_INTEGRATION = 'APERO_HEALTH_INTEGRATION',
        APERO_ELIGIBILITY_CHECK = 'APERO_ELIGIBILITY_CHECK',
        ELIGIBILITY_SUCCESS_ASSISTANCE_MESSAGE = 'ELIGIBILITY_SUCCESS_ASSISTANCE_MESSAGE',
        WELCOME_KIT_SHIP_VIA_TRUEPILL = 'WELCOME_KIT_SHIP_VIA_TRUEPILL',
        TRANSCARENT_SSO = 'TRANSCARENT_SSO',
        TRANSCARENT_SHARE_DATA = 'TRANSCARENT_SHARE_DATA',
        TRANSCARENT_BARIATRIC_SURGERY = 'TRANSCARENT_BARIATRIC_SURGERY',
        TRANSCARENT_MEDICATION_CARVE_OUT = 'TRANSCARENT_MEDICATION_CARVE_OUT',
        TRANSCARENT_SUPPRESS_EMAILS = 'TRANSCARENT_SUPPRESS_EMAILS',
        TRANSCARENT_USE_HEALTH_CONDITIONS = 'TRANSCARENT_USE_HEALTH_CONDITIONS',
        RPM_ORDER = 'RPM_ORDER',
        PROVIDER_CAN_ADD_SERVICE_REQUEST = 'PROVIDER_CAN_ADD_SERVICE_REQUEST',
        NOTIFY_PHYSICIANS_ABOUT_SYNC_CONSULT = 'NOTIFY_PHYSICIANS_ABOUT_SYNC_CONSULT',
        CRL_LAB_RESULTS = 'CRL_LAB_RESULTS',
        ONBOARDING_REQUEST_CALLBACK = 'ONBOARDING_REQUEST_CALLBACK',
        PMP_BILLING_ADDRESS_EDITING = 'PMP_BILLING_ADDRESS_EDITING',
        RXDIET_TASK_CREATE_MEAL_PLAN = 'RXDIET_TASK_CREATE_MEAL_PLAN',
        PATH_TO_HEALTHY_WEIGHT_PROGRAM = 'PATH_TO_HEALTHY_WEIGHT_PROGRAM',
        UNWRAP_ASYNC_MESSAGE_PROCESSING_EXCEPTION_LOGGING = 'UNWRAP_ASYNC_MESSAGE_PROCESSING_EXCEPTION_LOGGING',
        AMAZON_WEIGHTLOSS = 'AMAZON_WEIGHTLOSS',
        HANDLE_OBSERVATIONSAVED_EVENTS = 'HANDLE_OBSERVATIONSAVED_EVENTS',
        HANDLE_USERANSWEREDQUESTIONNAIRE_EVENTS = 'HANDLE_USERANSWEREDQUESTIONNAIRE_EVENTS',
        RXDIET_COMPLETE_MEAL_PLAN_JOB = 'RXDIET_COMPLETE_MEAL_PLAN_JOB',
        AUDITABLE_EVENTS_LOG_SYNCHRONOUSLY = 'AUDITABLE_EVENTS_LOG_SYNCHRONOUSLY',
        AMAZON_ELIGIBILITY_FILE_DOWNLOAD = 'AMAZON_ELIGIBILITY_FILE_DOWNLOAD',
        KWIKTRIP_ELIGIBILITY_FILE_DOWNLOAD = 'KWIKTRIP_ELIGIBILITY_FILE_DOWNLOAD',
        SMITHRX_ELIGIBILITY_FILE_DOWNLOAD = 'SMITHRX_ELIGIBILITY_FILE_DOWNLOAD',
        PREVENT_INELIGIBLE_SIGN_UP = 'PREVENT_INELIGIBLE_SIGN_UP',
        PROVIDE_PARTIAL_ELIGIBILITY_MATCHES = 'PROVIDE_PARTIAL_ELIGIBILITY_MATCHES',
        PRE_SIGN_UP_ELIGIBILITY_CHECK_AMAZON = 'PRE_SIGN_UP_ELIGIBILITY_CHECK_AMAZON',
        PRE_SIGN_UP_ELIGIBILITY_CHECK_WIPFLI = 'PRE_SIGN_UP_ELIGIBILITY_CHECK_WIPFLI',
        PRE_SIGN_UP_ELIGIBILITY_CHECK_KWIKTRIP = 'PRE_SIGN_UP_ELIGIBILITY_CHECK_KWIKTRIP',
        PRE_SIGN_UP_ELIGIBILITY_CHECK_SMITHRX = 'PRE_SIGN_UP_ELIGIBILITY_CHECK_SMITHRX',
        PROFILE_CACHE_IN_GET_REQUESTS = 'PROFILE_CACHE_IN_GET_REQUESTS',
        USE_LAB_PRECONDITIONS_FOR_INITIAL_LAB_ORDER = 'USE_LAB_PRECONDITIONS_FOR_INITIAL_LAB_ORDER',
        CREATE_INITIAL_APPLICATION_TASK = 'CREATE_INITIAL_APPLICATION_TASK',
    }

    /**
     * The current status of the feature.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }


}

